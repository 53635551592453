import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { APP_NAME } from 'constants/app.constant'
import { useSelector } from 'react-redux'

const Logo = props => {

	const LOGO_SRC_PATH = window.location.origin !== process.env.REACT_APP_DASHBOARD_SITE_URL ? '' : '/img/logo/'

	const { name } = useSelector((state) => state.userData.dashboard)

	const { 
		type, 
		mode, 
		gutter, 
		className,
		imgClass,
		style, 
		logoWidth,
		source,
	} = props

	const imgSource = source ? `${LOGO_SRC_PATH}${source}` : `${LOGO_SRC_PATH}logo-${mode}-${type}.svg`

	return (
		<div 
			className={classNames('logo', className, gutter)} 
			style={
				{
					...style,
					...{width: logoWidth}
				}
			}
		>
			<img 
				className={imgClass} 
				src={imgSource} 
				width={120}
				height={120}
				alt={`${window.location.origin !== process.env.REACT_APP_DASHBOARD_SITE_URL ? name : APP_NAME} logo`}
			/>
		</div>
	)
}

Logo.defaultProps = {
	mode: 'light',
	type: 'full',
	logoWidth: 'auto'
}

Logo.propTypes = {
	mode: PropTypes.oneOf(['light', 'dark']),
	type: PropTypes.oneOf(['full', 'streamline']),
	gutter: PropTypes.string,
	imgClass: PropTypes.string,
	source: PropTypes.string,
	logoWidth: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
}

export default Logo
