import { createSlice } from '@reduxjs/toolkit'

export const initialState = [];

export const pricingModels = createSlice({
	name: 'userData/pricingModels',
	initialState,
	reducers: {
        setPMData: (_, action) => action.payload,
        setEmptyPM: () => initialState,
	},
})

export const { setPMData, setEmptyPM } = pricingModels.actions

export default pricingModels.reducer