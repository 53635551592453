import { createSlice } from '@reduxjs/toolkit'

export const initialState = [];

export const navFotterSlice = createSlice({
	name: 'userData/navigationFooter',
	initialState,
	reducers: {
        setNavDataF: (_, action) => action.payload,
        deleteNavDataF: (state, action) => {
			const findIndex = state.findIndex(a => a.id === action.payload.id)
			state.splice(findIndex, 1)
        },
        setEmptyNF: () => initialState,
	},
})

export const { setNavDataF, setEmptyNF, deleteNavDataF } = navFotterSlice.actions

export default navFotterSlice.reducer