import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  id: "",
  name: "",
  domain: "",
  logoimg: "",
  logoimgdark: "",
  faviconimg: "",
  created_at: "",
  theme_color: "typeflo",
};

export const dashboardSlice = createSlice({
  name: "userData/dashboard",
  initialState,
  reducers: {
    setDashboardData: (_, action) => action.payload,
    setEmptyDashboard: () => initialState,
  },
});

export const { setDashboardData, setEmptyDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;