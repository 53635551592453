import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './style.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { init } from 'commandbar';
import { PostHogProvider } from 'posthog-js/react'
import { Crisp } from "crisp-sdk-web"
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

init('c51ab8d7');

Crisp.configure("e0e75cc5-e373-47b5-9568-d5dbf145bbb2", {
  autoload: false
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

if(window.location.host === 'localhost:3000') {
  root.render(
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  )
}else {
  root.render(
    <PostHogProvider 
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </PostHogProvider>
  )
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
