import { createSlice } from '@reduxjs/toolkit'

export const initialState = [];

export const postScheduleSlice = createSlice({
	name: 'userData/postsS',
	initialState,
	reducers: {
        setPostsSData: (_, action) => action.payload,
        deletePostSData: (state, action) => {
			const findIndex = state.findIndex(a => a.posttitle === action.payload.posttitle)
			state.splice(findIndex, 1)
        },
        setEmptyPS: () => initialState,
	},
})

export const { setPostsSData, setEmptyPS, deletePostSData } = postScheduleSlice.actions

export default postScheduleSlice.reducer