import { createSlice } from '@reduxjs/toolkit'

export const initialState = [];

export const tagsSlice = createSlice({
	name: 'userData/tags',
	initialState,
	reducers: {
        setTagsData: (_, action) => action.payload,
        deleteTagsData: (state, action) => {
			const findIndex = state.findIndex(a => a.id === action.payload.id)
			state.splice(findIndex, 1)
        },
        setEmptyTags: () => initialState,
	},
})

export const { setTagsData, setEmptyTags, deleteTagsData } = tagsSlice.actions

export default tagsSlice.reducer