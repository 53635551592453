import { createSlice } from '@reduxjs/toolkit'

export const initialState = [];

export const pageSlice = createSlice({
	name: 'userData/pages',
	initialState,
	reducers: {
        setPageData: (_, action) => action.payload,
        deletePageData: (state, action) => {
			const findIndex = state.findIndex(a => a.pagetitle === action.payload.pagetitle)
			state.splice(findIndex, 1)
        },
        setEmptyPages: () => initialState,
	},
})

export const { setPageData, setEmptyPages, deletePageData } = pageSlice.actions

export default pageSlice.reducer