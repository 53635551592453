import { createSlice } from '@reduxjs/toolkit'

export const initialState = [];

export const ctasSlice = createSlice({
	name: 'userData/ctas',
	initialState,
	reducers: {
        setCtasData: (_, action) => action.payload,
        deleteCtasData: (state, action) => {
			const findIndex = state.findIndex(a => a.id === action.payload.id)
			state.splice(findIndex, 1)
        },
        setEmptyCtas: () => initialState,
	},
})

export const { setCtasData, setEmptyCtas, deleteCtasData } = ctasSlice.actions

export default ctasSlice.reducer