import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  onboard: null,
};

export const authorSlice = createSlice({
  name: "userData/onboardStat",
  initialState,
  reducers: {
    updateOnboard: (state, action) => {
      state.onboard = action.payload;
    },
    setEmptyOA: () => initialState,
  },
});

export const {
  updateOnboard,
  setEmptyOA,
} = authorSlice.actions;

export default authorSlice.reducer;
