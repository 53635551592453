import React from 'react'
import PropTypes from 'prop-types'

const TextEllipsisH6 = props => {

	const { text, maxTextCount } = props

	return (
		<h6>
			{(text && text.length) > maxTextCount ? text.substring(0, (maxTextCount - 3)) + '...' : text}
		</h6>
	)
}

TextEllipsisH6.propTypes = {
	text: PropTypes.string,
}

TextEllipsisH6.defaultProps = {
	text: '',
	maxTextCount: 0
}

export default TextEllipsisH6