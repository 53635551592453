import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, sbGoogleSignin, sbMagicLinkSignin, sbverifyOtp ,apiSignOut, sbGoogleSignOut } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { setEmptyOA } from 'store/userData/onboardStatSlice'
import { setEmptyA } from 'store/userData/authorSlice'
import { setEmptyN } from 'store/userData/navSlice'
import { setEmptyNF } from 'store/userData/navFotterSlice'
import { setEmptyPages } from "store/userData/pageSlice";
import { setEmptyPagesD } from "store/userData/pageDraftSlice";
import { setEmptySN } from "store/userData/socSlice";
import { setEmptyRA } from "store/userData/refauthSlice";
import { setEmptyPM } from "store/userData/pricingModels";
import { setEmptyUPD } from "store/userData/userPricing";
import { setEmptyCtas } from 'store/userData/ctasSlice';
import { useQueryClient } from "@tanstack/react-query";
import { Crisp } from "crisp-sdk-web";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import useDarkMode from "utils/hooks/useDarkMode";

function useAuth() {

    const dispatch = useDispatch()

	const queryClient = useQueryClient()

    const navigate = useNavigate()

	const [isDark, setIsDark] = useDarkMode()

	const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async ({ userName, password }) => {
        try {
			const resp = await apiSignIn({ userName, password })
			if (resp.data) {
				const { token } = resp.data
				dispatch(onSignInSuccess(token))
				if(resp.data.user) {
					dispatch(setUser(resp.data.user || { 
						avatar: '', 
						userName: 'Anonymous', 
						authority: ['USER'], 
						email: ''
					}))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                return {
                    status: 'success',
                    message: ''
                }
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
		}
    }

    const magicLinkSignin = async (email, dataOptions) => {
        try {
			const resp = await sbMagicLinkSignin(email, dataOptions)
			if(resp.error) {
				return {
					status: 'failed',
					message: resp.error?.message || resp.error.toString()
				}
			}
			if (resp.data) {
				console.log(resp.data)
				// const { access_token } = resp.session
				// dispatch(onSignInSuccess(access_token))
				// if(resp.user) {
				// 	dispatch(setUser(resp.user || { 
				// 		avatar: '', 
				// 		userName: 'Anonymous', 
				// 		authority: ['USER'], 
				// 		email: ''
				// 	}))
				// }
				// //const redirectUrl = query.get(REDIRECT_URL_KEY)
				// navigate(appConfig.authenticatedEntryPath)
                return {
                    status: 'success',
                    message: resp.data
                }
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.message || errors.toString()
            }
		}
    }

    const verifyOtp = async (email, otp) => {
        try {
			const resp = await sbverifyOtp(email, otp);
			if(resp.error) {
				return {
					status: 'failed',
					message: resp.error?.message || "There was an error signing in. Please try again after sometime"
				}
			}
			if (resp.data) {
				console.log(resp.data);
                return {
                    status: 'success',
                    message: resp.data
                }
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.message || errors.toString()
            }
		}
    }

    const googleSignin = async () => {
        try {
			const resp = await sbGoogleSignin()
			if(resp.error) {
				return {
					status: 'failed',
					message: resp.error?.message || resp.error.toString()
				}
			}
			if (resp.data) {
				console.log(resp.data)
				// const { access_token } = resp.session
				// dispatch(onSignInSuccess(access_token))
				// if(resp.user) {
				// 	dispatch(setUser(resp.user || { 
				// 		avatar: '', 
				// 		userName: 'Anonymous', 
				// 		authority: ['USER'], 
				// 		email: ''
				// 	}))
				// }
				// //const redirectUrl = query.get(REDIRECT_URL_KEY)
				// navigate(appConfig.authenticatedEntryPath)
                // return {
                //     status: 'success',
                //     message: ''
                // }
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.message || errors.toString()
            }
		}
    }

    const handleSignOut = async ()  => {
		queryClient.removeQueries();

		await Promise.all([
			dispatch(setEmptyOA()),
			dispatch(setEmptyA()),
			dispatch(setEmptyN()),
			dispatch(setEmptyNF()),
			dispatch(setEmptyPages()),
			dispatch(setEmptyPagesD()),
			dispatch(setEmptySN()),
			dispatch(setEmptyRA()),
			dispatch(setEmptyPM()),
			dispatch(setEmptyUPD()),
			dispatch(setEmptyCtas()),
			dispatch(setUser(initialState)),
		]);

		window.CommandBar.shutdown();
		Crisp.chat.close();
		Crisp.chat.hide();
		setIsDark("light");

		dispatch(onSignOutSuccess());

		navigate(appConfig.unauthenticatedEntryPath);
	}

    const googlesignOut = async () => {
		try {
			await sbGoogleSignOut();
			secureLocalStorage.removeItem('allowed-lists');
			handleSignOut()
		} catch (errors) {
			handleSignOut()
		}
	}

    const signOut = async () => {
		try {
			await apiSignOut()
			handleSignOut()
		} catch (errors) {
			handleSignOut()
		}
	}
    
    const whitelabelSignout = async () => {
		const refreshToken = secureLocalStorage.getItem("commandbar");

		const options = {
			method: "POST",
			url: process.env.REACT_APP_BACKEND_URL + "/api/whitelabel/logout",
			data: {},
			headers: {
			  Authorization: `Bearer ${refreshToken}`
			}
		};
		  
		await axios(options).catch(function (error) { console.log(error); return error });

        secureLocalStorage.removeItem('crisp-chat-api');
        secureLocalStorage.removeItem('commandbar');
        secureLocalStorage.removeItem('posthog-api');
		secureLocalStorage.removeItem('allowed-lists');

		handleSignOut();
	}

    return {
        authenticated: token && signedIn,
        signIn,
		googleSignin,
		magicLinkSignin,
		verifyOtp,
		googlesignOut,
		whitelabelSignout,
        signOut
    }
}

export default useAuth