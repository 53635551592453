import { combineReducers } from "@reduxjs/toolkit";
import author from "./authorSlice";
import refAuth from "./refauthSlice";
import posts from "./postSlice";
import postsD from "./postDraftSlice";
import postsS from "./postScheduleSlice";
import pages from "./pageSlice";
import pagesD from "./pageDraftSlice";
import pricingModels from "./pricingModels";
import userPricing from "./userPricing";
import category from "./categorySlice";
import tags from "./tagSlice";
import navigation from "./navSlice";
import navigationFooter from "./navFotterSlice";
import onboardStat from "./onboardStatSlice";
import org from "./orgSlice";
import socNav from "./socSlice";
import cta from "./ctaSlice";
import ctas from "./ctasSlice";
import whitelabel from "./whitelabelSlice";
import dashboard from "./dashboardSlice";
import teams from "./teamsSlice";

const reducer = combineReducers({
  author,
  refAuth,
  posts,
  postsD,
  postsS,
  pages,
  pagesD,
  pricingModels,
  userPricing,
  category,
  tags,
  navigation,
  navigationFooter,
  onboardStat,
  org,
  socNav,
  cta,
  ctas,
  whitelabel,
  dashboard,
  teams,
});

export default reducer;
