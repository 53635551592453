import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  name: "",
  logoimg: null,
  faviconimg: null,
  domain: "",
};

export const setupWLSlice = createSlice({
  name: "setup",
  initialState,
  reducers: {
    stepOne: (state, action) => {
      state.name = action.payload.name;
      state.logoimg = action.payload.logoimg;
      state.faviconimg = action.payload.faviconimg;
    },
    stepTwo: (state, action) => {
      state.domain = action.payload.domain;
    },
    clearSteps: () => initialState,
  },
});

export const { stepOne, stepTwo, clearSteps } = setupWLSlice.actions;

export default setupWLSlice.reducer;