import { createSlice } from '@reduxjs/toolkit'

export const initialState = [];

export const refauthSlice = createSlice({
	name: 'userData/refAuth',
	initialState,
	reducers: {
        setrefAuthData: (_, action) => action.payload,
        deleterefAuthData: (state, action) => {
			const findIndex = state.findIndex(a => a.id === action.payload.id)
			state.splice(findIndex, 1)
        },
        setEmptyRA: () => initialState,
	},
})

export const { setrefAuthData, setEmptyRA, deleterefAuthData } = refauthSlice.actions

export default refauthSlice.reducer
