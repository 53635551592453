import React from 'react'

function NoRecord({ text = "" }) {
  return (
    <div className='text-center w-full text-1xl mt-5 py-5 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md'>
        <p>{text}</p>
    </div>
  )
}

export default NoRecord