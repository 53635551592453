import { createSlice } from '@reduxjs/toolkit'

export const initialState = [];

export const postSlice = createSlice({
	name: 'userData/postsD',
	initialState,
	reducers: {
        setPostsDData: (_, action) => action.payload,
        deletePostDData: (state, action) => {
			const findIndex = state.findIndex(a => a.posttitle === action.payload.posttitle)
			state.splice(findIndex, 1)
        },
        setEmptyPD: () => initialState,
	},
})

export const { setPostsDData, setEmptyPD, deletePostDData } = postSlice.actions

export default postSlice.reducer