import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  cta: false,
};

export const ctaSlice = createSlice({
  name: "userData/cta",
  initialState,
  reducers: {
    setCta: (_, action) => action.payload,
    setEmptyCta: () => initialState,
  },
});

export const { setCta, setEmptyCta } = ctaSlice.actions;

export default ctaSlice.reducer;
