import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  metatitle: "",
  blog_name: "",
  logoimg: null,
  faviconimg: null,
  title: "",
  description: "",
  //timezone: "",
};

export const onboardSlice = createSlice({
  name: "onboard",
  initialState,
  reducers: {
    stepOne: (state, action) => {
      state.metatitle = action.payload.metatitle;
      state.blog_name = action.payload.blogName;
      state.logoimg = action.payload.logoimg;
      state.faviconimg = action.payload.faviconimg;
      //state.timezone = action.payload.timezone;
    },
    stepTwo: (state, action) => {
      state.title = action.payload.title;
      state.description = action.payload.description;
    },
    clearSteps: () => initialState,
  },
});

export const { stepOne, stepTwo, clearSteps } = onboardSlice.actions;

export default onboardSlice.reducer;
