import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  id: "",
  name: "",
  domain: "",
  logoimg: "",
  logoimgdark: "",
  faviconimg: "",
  created_at: "",
  cus_domain_d: [
    { name: "API Hit", status: false },
    { name: "DNS", status: false },
    { name: "Resolving", status: false },
    { name: "SSL", status: false },
  ],
  theme_color: "typeflo",
};

export const whitelabelSlice = createSlice({
  name: "userData/whitelabel",
  initialState,
  reducers: {
    setWLData: (_, action) => action.payload,
    updateWLData: (state, action) => {
      state.name = action.payload.name;
      state.logoimg = action.payload.logoimg;
      state.logoimgdark = action.payload.logoimgdark;
      state.faviconimg = action.payload.faviconimg;
    },
    updateWLDomain: (state, action) => {
      state.domain = action.payload.domain;
    },
    updateWLCusDomainD: (state, action) => {
      state.cus_domain_d = action.payload.cus_domain_d;
    },
    updateWLTheme: (state, action) => {
      state.theme_color = action.payload.theme_color;
    },
    setEmptyWL: () => initialState,
  },
});

export const { setWLData, updateWLData, updateWLDomain, updateWLCusDomainD, setEmptyWL, updateWLTheme } = whitelabelSlice.actions;

export default whitelabelSlice.reducer;
