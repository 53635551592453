import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    id: '',
    avatar_url: '',
    email: '',
    email_verified: false,
    full_name: '',
    iss: '',
    name: '',
    picture: '',
    provider: '',
    provider_id: '',
    sub: '',
    authority: [],
    additionalDetails: [],
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
        setUser: (_, action) => action.payload,
        updateMetaData: (state, action) => {
            state.full_name = action.payload.full_name;
            state.avatar_url = action.payload.avatar_url;
        },
        updateadditionalDetails: (state, action) => {
            state.additionalDetails = action.payload.additionalDetails;
        },
        userLoggedOut: () => initialState,
	},
})

export const { setUser, updateMetaData, updateadditionalDetails, userLoggedOut } = userSlice.actions

export default userSlice.reducer;