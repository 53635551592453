import { createSlice } from '@reduxjs/toolkit'

export const initialState = [];

export const userPricing = createSlice({
	name: 'userData/userPricing',
	initialState,
	reducers: {
        setUserPricingData: (_, action) => action.payload,
        setEmptyUPD: () => initialState,
	},
})

export const { setUserPricingData, setEmptyUPD } = userPricing.actions

export default userPricing.reducer