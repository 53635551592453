import ApiService from "./ApiService";
import supabaseClient from "utils/supabaseClient";

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: "/sign-in",
    method: "post",
    data,
  });
}

export async function sbGoogleSignin() {

  const { data, error } = await supabaseClient.auth.signInWithOAuth({
    provider: "google",
    options: {
      redirectTo: process.env.REACT_APP_DASHBOARD_SITE_URL,
    }
  });
  
  return {
    data,
    error,
  };
}

export async function sbMagicLinkSignin(email, dataOptions) {

  const { data, error } = await supabaseClient.auth.signInWithOtp({
    email,
    options: {
      data: {
        ...dataOptions,
      },
      emailRedirectTo: process.env.REACT_APP_DASHBOARD_SITE_URL,
    },
  })
  return {
    data,
    error,
  };
}

export async function sbverifyOtp (email, otp) {
  const { data: { session }, error } = await supabaseClient.auth.verifyOtp({
    email,
    token: otp.toString(),
    type: 'email',
  });

  return {
    data: session,
    error,
  };
}  

export async function sbGoogleSigninWithScopes() {
  const { data, error } = await supabaseClient.auth.signInWithOAuth({
    provider: "google",
    options: {
      queryParams: { access_type: "offline" },
      scopes: "https://www.googleapis.com/auth/drive.readonly",
    },
  });
  return {
    data,
    error,
  };
}

export async function sbGoogleSignOut() {
  const signout = await supabaseClient.auth.signOut();
  return signout;
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "/sign-up",
    method: "post",
    data,
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: "/sign-out",
    method: "post",
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: "/forgot-password",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: "/reset-password",
    method: "post",
    data,
  });
}
