import React, { useEffect, useContext, createContext } from 'react';

const CrispContext = createContext();

export const CrispProvider = ({ children }) => {
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "e0e75cc5-e373-47b5-9568-d5dbf145bbb2";

    (function () {
      var d = document;
      var s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);

  return (
    <CrispContext.Provider value={window.$crisp}>
      {children}
    </CrispContext.Provider>
  );
};

export const useCrisp = () => {
    return useContext(CrispContext);
};