import { createSlice } from '@reduxjs/toolkit'

export const initialState = [];

export const socSlice = createSlice({
	name: 'userData/socNav',
	initialState,
	reducers: {
        setSocData: (_, action) => action.payload,
        deleteSocData: (state, action) => {
			const findIndex = state.findIndex(a => a.id === action.payload.id)
			state.splice(findIndex, 1)
        },
        setEmptySN: () => initialState,
	},
})

export const { setSocData, setEmptySN, deleteSocData } = socSlice.actions

export default socSlice.reducer