import { createSlice } from '@reduxjs/toolkit'

export const initialState = [];

export const orgSlice = createSlice({
	name: 'userData/org',
	initialState,
	reducers: {
        setOrgData: (_, action) => action.payload,
        deleteOrgData: (state, action) => {
			const findIndex = state.findIndex(a => a.id === action.payload.id)
			state.splice(findIndex, 1)
        },
        setEmptyOrg: () => initialState,
	},
})

export const { setOrgData, setEmptyOrg, deleteOrgData } = orgSlice.actions

export default orgSlice.reducer