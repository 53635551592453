import { createSlice } from '@reduxjs/toolkit'

export const initialState = [];

export const teamsSlice = createSlice({
	name: 'userData/teams',
	initialState,
	reducers: {
        setTeamsData: (_, action) => action.payload,
        deleteTeamsData: (state, action) => {
			const findIndex = state.findIndex(a => a.id === action.payload.id)
			state.splice(findIndex, 1)
        },
        setEmptyTeams: () => initialState,
	},
})

export const { setTeamsData, setEmptyTeams, deleteTeamsData } = teamsSlice.actions

export default teamsSlice.reducer