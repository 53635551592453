import React, { memo, useMemo, lazy, Suspense, useState, useEffect } from 'react'
import { Loading } from 'components/shared'
import { Error } from "components/ui"
import { useDispatch, useSelector } from 'react-redux'
import { setDashboardData, setEmptyDashboard } from 'store/userData/dashboardSlice'
import { 
	LAYOUT_TYPE_CLASSIC, 
	LAYOUT_TYPE_MODERN, 
	LAYOUT_TYPE_SIMPLE,
	LAYOUT_TYPE_STACKED_SIDE,
	LAYOUT_TYPE_DECKED,
	LAYOUT_TYPE_BLANK
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import supabaseClient from 'utils/supabaseClient'
import {Helmet} from "react-helmet"
import faviconImg from "assets/images/favicon.png"
import { setThemeColor } from 'store/theme/themeSlice'

const layouts = {
	[LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
	[LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayoutv2')),
	[LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
	[LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
	[LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
	[LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {

	const dispatch = useDispatch()

	const layoutType = useSelector((state) => state.theme.layout.type)
    const dashboardDet = useSelector((state) => state.userData.dashboard)

	if(typeof dashboardDet === "undefined") {
		dispatch(setEmptyDashboard());
		window.location.reload();
	}

	const { authenticated } = useAuth()

	const [error, setError] = useState(false);
	const [dashboard, setDashboard] = useState(dashboardDet);

	useDirection()

	useLocale()

	const AppLayout = useMemo(() => {
		if (authenticated) {
			return layouts[layoutType]
		}
		return lazy(() => import('./AuthLayout'))
	}, [layoutType, authenticated])

	const fetchDashboard = async () => {
		const dashDomain = process.env.REACT_APP_DASHBOARD_SITE_URL === 'http://localhost:3000' ? "dashboard.siterank.ai" : window.location.origin.replace('https://', "");

		const { data, error } = await supabaseClient
		.from("whitelabel")
		.select("*")
		.eq("domain", dashDomain)
		.single();

		if(error) {
			return setError(true);
		}
		if(data) {
			setError(false);
			setDashboard(data);
			dispatch(setDashboardData(data));
			
			dispatch(setThemeColor(data.theme_colour))
		}
	}

	useEffect(() => {

		(window.location.origin !== process.env.REACT_APP_DASHBOARD_SITE_URL) && fetchDashboard();
		
	}, []);

	if (error) {
		return <Error />;
	} else {

		return (
			<Suspense 
				fallback={
					<div className="flex flex-auto flex-col h-[100vh]">
						<Loading loading={true} />
					</div>
				}
			>
				<Helmet>
					<meta charSet="utf-8" />
					<title>{window.location.origin !== process.env.REACT_APP_DASHBOARD_SITE_URL ? dashboard.name : "Typeflo-Dashboard"}</title>
					<link rel="icon" href={window.location.origin !== process.env.REACT_APP_DASHBOARD_SITE_URL ? dashboard.faviconimg : faviconImg} />
				</Helmet>
				<AppLayout />
			</Suspense>
		)
	}
}

export default memo(Layout)