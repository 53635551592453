import { createSlice } from '@reduxjs/toolkit'

export const initialState = [];

export const pageSlice = createSlice({
	name: 'userData/pagesD',
	initialState,
	reducers: {
        setPageDData: (_, action) => action.payload,
        deletePageDData: (state, action) => {
			const findIndex = state.findIndex(a => a.pagetitle === action.payload.pagetitle)
			state.splice(findIndex, 1)
        },
        setEmptyPagesD: () => initialState,
	},
})

export const { setPageDData, setEmptyPagesD, deletePageDData } = pageSlice.actions

export default pageSlice.reducer