import BaseService from "./BaseService";
import supabaseClient from "utils/supabaseClient";

const ApiService = {
  fetchData(param) {
    return new Promise((resolve, reject) => {
      BaseService(param)
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
};

export async function sbAuthor(authId) {
  const { data, error } = await supabaseClient
    .from("authors")
    .select(`*, org!inner(id, name), custom_code!custom_code(*), integrations!integrations(*), theme!theme(*), users!users(*), pages!authors_home_page_fkey(id, title)`)
    .eq("id", authId);

  if(error || !data) {
    return { error: 'Author Fetch Error', data: null };
  }

  if(data.length === 0) {
    console.log('No author found, fetching author without org');
    
    const { data: authorNoOrgData, error: authorNoOrgError } = await supabaseClient
    .from("authors")
    .select(`*, custom_code!custom_code(*), integrations!integrations(*), theme!theme(*), users!users(*), pages!authors_home_page_fkey(id, title)`)
    .eq("id", authId);

    if(authorNoOrgError || !authorNoOrgData) {
      return { error: 'Author Fetch Error', data: null };
    }

    return { error: null, data: authorNoOrgData };
  }

  return { error, data };
}

export async function sbUpload(bucket, imagepath, image) {
  const { data, error } = await supabaseClient.storage
    .from(bucket)
    .upload(imagepath, image, {
      upsert: true,
    });

  if (error) {
    return { error, publicURL: null };
  }
  if (data) {
    const {
      data: { publicUrl },
      error,
    } = await supabaseClient.storage.from(bucket).getPublicUrl(imagepath);
    const publicURL = publicUrl;

    return { error, publicURL };
  }
}

export async function sbUpdate(table, authId, updateData, type) {
  const { data, error } = await supabaseClient
    .from(table)
    .update(updateData)
    .eq(type, authId)
    .select();

  return { error, data };
}

export async function checkDetails(user, type, query, table) {
  const { data, error } = await supabaseClient
    .from(table)
    .select(query)
    .eq(type, user)
    .single();

  if (error) return error;
  else return data;
}

export async function checkDetailsv2(table, query, type, user, type2, user2) {
  const { data, error } = await supabaseClient
    .from(table)
    .select(query)
    .eq(type, user)
    .eq(type2, user2)
    .single();

  if (error) return error;
  else return data;
}

export async function checkDetailsv3(table, query, type, user, type2, user2, type3, user3) {
  const { data, error } = await supabaseClient
    .from(table)
    .select(query)
    .eq(type, user)
    .eq(type2, user2);

  return { data, error };
}

export async function sbInsert(table, insertData) {
  const { data, error } = await supabaseClient
    .from(table)
    .insert(insertData)
    .select();

  return { error, data };
}

export async function sbInsertAndSelect(table, insertData, selectData) {
  const { data, error } = await supabaseClient
    .from(table)
    .insert(insertData)
    .select(selectData);

  return { error, data };
}

export async function sbSelect(table, content, type, authId, inPage, fnPage) {
  const { data, error } = await supabaseClient
    .from(table)
    .select(content)
    .eq(type, authId)
    .range(inPage, fnPage)
    .order("created_at", { ascending: false });

  return { error, data };
}

export async function sbSelectDefault(table, content, type, authId) {
  const { data, error } = await supabaseClient
    .from(table)
    .select(content)
    .eq(type, authId);

  return { error, data };
}

export async function sbStorageDelete(bucket, deleteData) {
  const { data, error } = await supabaseClient.storage
    .from(bucket)
    .remove(deleteData);

  return { error, data };
}

export async function sbDelete(table, id) {
  const { data, error } = await supabaseClient
    .from(table)
    .delete()
    .eq("id", id)
    .select();

  return { error, data };
}

export async function sbUserDataDelete(table, type, authId, type2, authId2) {
  const { data, error } = await supabaseClient
    .from(table)
    .delete()
    .eq(type, authId)
    .eq(type2, authId2)
    .select();

  return { error, data };
}

export default ApiService;
